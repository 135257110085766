import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

class About extends React.Component {
    render() {
        return (
            <div className='paddingBottom'>
                <Header />

                <h3>Contact</h3>

                <h4>
                    Want your school added to LiveBell.org?
                </h4>
                <p className='indent'>
                    We'd love to host your school on our platform! Send us the details of your school, along with a link to your school's website and the bell schedules, then we'll add it to the system!
                </p>
                <p className='indent'>
                    School admin and staff inquire for more opportunities.
                </p>
                <p className='indent'>
                    Contact Kyle Wistrand at: &nbsp;
                    <a href="mailto:kyle@kylewistrand.com">
                        kyle@kylewistrand.com
                    </a>
                </p>

                <h4>
                    Have a bug to report?
                </h4>
                <a className='indent' href="mailto:kyle@kylewistrand.com?subject=Schedule App Bug">
                    Send a Bug Report
                </a>
                
                <h4>
                    All other inquiries
                </h4>
                <p className='indent'>
                    Contact Kyle Wistrand at: &nbsp;
                    <a href="mailto:kyle@kylewistrand.com">
                        kyle@kylewistrand.com
                    </a>
                </p>
                <Footer />
            </div>
        );
    }
}

export default About