import React from 'react';
import { Link } from "react-router-dom";
import '../styles/Header.css';

function Header(props) {
    return (
        <div>
            <div className='flexContainer'>
                <Link to="/" className='noUnderline'>
                    <h3 className='siteTitle'>
                        <span role="img" aria-label="Clock emoji">⏰</span>
                        <span>LiveBell.org</span>
                    </h3>
                </Link>
                <div className='spacer'></div>
                {props.school?
                    <button onClick={() => props.unset()} className="schoolInfo" aria-label={`Change school from ${props.school.name}`}>
                        <small className='schoolName'>
                            <span className="noMobile">{props.school.name}</span>
                            <span className="mobileOnly">{props.school.schoolCode}</span>
                        </small>
                        <small className='changeSchool'>change</small>
                    </button>
                :""}
            </div>
            <div className='navigation'>
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <hr />
        </div>
    );
}

export default Header