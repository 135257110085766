export function setCookie(name, value, days) {
    let d = new Date();
    d.setHours(0,0,0,0);
    d.setDate(d.getDate() + days);
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookies() {
    return document.cookie.split('; ').reduce((accum, current) => {
        let [name, value] = current.split('=');
        accum[name] = value;
        return accum
    }, {});
}

export function schoolCodeToObject(data, schoolCode) {
    return data.schedules.find(o => o.schoolCode === schoolCode.toUpperCase())
}