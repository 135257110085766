export default {
    schedules: [
        {
            name: 'Skyview High School',
            schoolCode: 'SHS',
            types: [
                {
                    name: 'Daily Schedule - Lunch 1',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 20
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 25
                            },
                            end: {
                                hour: 9,
                                minute: 15
                            }
                        },
                        {
                            name: 'Tutorial',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 9,
                                minute: 50
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 55
                            },
                            end: {
                                hour: 10,
                                minute: 45
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 10,
                                minute: 50
                            },
                            end: {
                                hour: 11,
                                minute: 20
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 11,
                                minute: 25
                            },
                            end: {
                                hour: 12,
                                minute: 15
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 12,
                                minute: 20
                            },
                            end: {
                                hour: 13,
                                minute: 10
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 13,
                                minute: 15
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                },
                {
                    name: 'Daily Schedule - Lunch 2',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 20
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 25
                            },
                            end: {
                                hour: 9,
                                minute: 15
                            }
                        },
                        {
                            name: 'Tutorial',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 9,
                                minute: 50
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 55
                            },
                            end: {
                                hour: 10,
                                minute: 45
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 10,
                                minute: 50
                            },
                            end: {
                                hour: 11,
                                minute: 40
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 11,
                                minute: 45
                            },
                            end: {
                                hour: 12,
                                minute: 15
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 12,
                                minute: 20
                            },
                            end: {
                                hour: 13,
                                minute: 10
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 13,
                                minute: 15
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                },
                {
                    name: '40 Minute Early Release - Lunch 1',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 18
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 23
                            },
                            end: {
                                hour: 9,
                                minute: 16
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 21
                            },
                            end: {
                                hour: 10,
                                minute: 9
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 10,
                                minute: 14
                            },
                            end: {
                                hour: 10,
                                minute: 44
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 10,
                                minute: 49
                            },
                            end: {
                                hour: 11,
                                minute: 39
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 11,
                                minute: 44
                            },
                            end: {
                                hour: 12,
                                minute: 32
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 12,
                                minute: 37
                            },
                            end: {
                                hour: 13,
                                minute: 25
                            }
                        }
                    ]
                },
                {
                    name: '40 Minute Early Release - Lunch 2',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 18
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 23
                            },
                            end: {
                                hour: 9,
                                minute: 16
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 21
                            },
                            end: {
                                hour: 10,
                                minute: 9
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 10,
                                minute: 14
                            },
                            end: {
                                hour: 11,
                                minute: 4
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 11,
                                minute: 9
                            },
                            end: {
                                hour: 11,
                                minute: 39
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 11,
                                minute: 44
                            },
                            end: {
                                hour: 12,
                                minute: 32
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 12,
                                minute: 37
                            },
                            end: {
                                hour: 13,
                                minute: 25
                            }
                        }
                    ]
                },
                {
                    name: 'Afternoon Assembly (Gym) - Lunch 1',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 20
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 25
                            },
                            end: {
                                hour: 9,
                                minute: 15
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 10,
                                minute: 10
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 10,
                                minute: 15
                            },
                            end: {
                                hour: 10,
                                minute: 45
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 10,
                                minute: 50
                            },
                            end: {
                                hour: 11,
                                minute: 35
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 11,
                                minute: 40
                            },
                            end: {
                                hour: 12,
                                minute: 25
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 12,
                                minute: 30
                            },
                            end: {
                                hour: 13,
                                minute: 20
                            }
                        },
                        {
                            name: 'Assembly',
                            begin: {
                                hour: 13,
                                minute: 25
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                },
                {
                    name: 'Afternoon Assembly (Gym) - Lunch 2',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 20
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 25
                            },
                            end: {
                                hour: 9,
                                minute: 15
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 10,
                                minute: 10
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 11,
                                minute: 15
                            },
                            end: {
                                hour: 11,
                                minute: 0
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 11,
                                minute: 5
                            },
                            end: {
                                hour: 11,
                                minute: 35
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 11,
                                minute: 40
                            },
                            end: {
                                hour: 12,
                                minute: 25
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 12,
                                minute: 30
                            },
                            end: {
                                hour: 13,
                                minute: 20
                            }
                        },
                        {
                            name: 'Assembly',
                            begin: {
                                hour: 13,
                                minute: 25
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                },
                {
                    name: 'Auditorium Assembly - Lunch 1',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 20
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 25
                            },
                            end: {
                                hour: 9,
                                minute: 15
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 11,
                                minute: 0
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 11,
                                minute: 5
                            },
                            end: {
                                hour: 11,
                                minute: 35
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 11,
                                minute: 40
                            },
                            end: {
                                hour: 12,
                                minute: 25
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 12,
                                minute: 30
                            },
                            end: {
                                hour: 13,
                                minute: 15
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 13,
                                minute: 20
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                },
                {
                    name: 'Auditorium Assembly - Lunch 2',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 20
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 8,
                                minute: 25
                            },
                            end: {
                                hour: 9,
                                minute: 15
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 11,
                                minute: 0
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 11,
                                minute: 5
                            },
                            end: {
                                hour: 11,
                                minute: 50
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 11,
                                minute: 55
                            },
                            end: {
                                hour: 12,
                                minute: 25
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 12,
                                minute: 30
                            },
                            end: {
                                hour: 13,
                                minute: 15
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 13,
                                minute: 20
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                },
                {
                    name: 'Visit VPS (Patron Tour) - Lunch 1',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 35
                            }
                        },
                        {
                            name: 'Tutorial',
                            begin: {
                                hour: 8,
                                minute: 40
                            },
                            end: {
                                hour: 9,
                                minute: 10
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 9,
                                minute: 15
                            },
                            end: {
                                hour: 10,
                                minute: 20
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 10,
                                minute: 25
                            },
                            end: {
                                hour: 11,
                                minute: 8
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 11,
                                minute: 13
                            },
                            end: {
                                hour: 11,
                                minute: 43
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 11,
                                minute: 48
                            },
                            end: {
                                hour: 12,
                                minute: 31
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 12,
                                minute: 36
                            },
                            end: {
                                hour: 13,
                                minute: 18
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 13,
                                minute: 23
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                },
                {
                    name: 'Visit VPS (Patron Tour) - Lunch 2',
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 6,
                                minute: 35
                            },
                            end: {
                                hour: 7,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Period',
                            begin: {
                                hour: 7,
                                minute: 30
                            },
                            end: {
                                hour: 8,
                                minute: 35
                            }
                        },
                        {
                            name: 'Tutorial',
                            begin: {
                                hour: 8,
                                minute: 40
                            },
                            end: {
                                hour: 9,
                                minute: 10
                            }
                        },
                        {
                            name: 'Second Period',
                            begin: {
                                hour: 9,
                                minute: 15
                            },
                            end: {
                                hour: 10,
                                minute: 20
                            }
                        },
                        {
                            name: 'Third Period',
                            begin: {
                                hour: 10,
                                minute: 25
                            },
                            end: {
                                hour: 11,
                                minute: 8
                            }
                        },
                        {
                            name: 'Fourth Period',
                            begin: {
                                hour: 11,
                                minute: 13
                            },
                            end: {
                                hour: 11,
                                minute: 56
                            }
                        },
                        {
                            name: 'Lunch',
                            begin: {
                                hour: 12,
                                minute: 1
                            },
                            end: {
                                hour: 12,
                                minute: 31
                            }
                        },
                        {
                            name: 'Fifth Period',
                            begin: {
                                hour: 12,
                                minute: 36
                            },
                            end: {
                                hour: 13,
                                minute: 18
                            }
                        },
                        {
                            name: 'Sixth Period',
                            begin: {
                                hour: 13,
                                minute: 23
                            },
                            end: {
                                hour: 14,
                                minute: 5
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'Evergreen High School',
            schoolCode: 'EHS',
            types: [
                {
                    name: 'Regular - Lunch 1',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 7,
                                minute: 40
                            },
                            end: {
                                hour: 8,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period One',
                            begin: {
                                hour: 8,
                                minute: 40
                            },
                            end: {
                                hour: 9,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period Two',
                            begin: {
                                hour: 9,
                                minute: 40
                            },
                            end: {
                                hour: 10,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period Three',
                            begin: {
                                hour: 10,
                                minute: 40
                            },
                            end: {
                                hour: 11,
                                minute: 40
                            }
                        },
                        {
                            name: 'First Lunch',
                            begin: {
                                hour: 11,
                                minute: 40
                            },
                            end: {
                                hour: 12,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period Four A',
                            begin: {
                                hour: 12,
                                minute: 15
                            },
                            end: {
                                hour: 13,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period Five',
                            begin: {
                                hour: 13,
                                minute: 15
                            },
                            end: {
                                hour: 14,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period Six',
                            begin: {
                                hour: 14,
                                minute: 15
                            },
                            end: {
                                hour: 15,
                                minute: 10
                            }
                        },
                        {
                            name: 'After-School Tutoring',
                            begin: {
                                hour: 15,
                                minute: 20
                            },
                            end: {
                                hour: 16,
                                minute: 20
                            }
                        }
                    ]
                },
                {
                    name: 'Regular - Lunch 2',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 7,
                                minute: 40
                            },
                            end: {
                                hour: 8,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period One',
                            begin: {
                                hour: 8,
                                minute: 40
                            },
                            end: {
                                hour: 9,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period Two',
                            begin: {
                                hour: 9,
                                minute: 40
                            },
                            end: {
                                hour: 10,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period Three',
                            begin: {
                                hour: 10,
                                minute: 40
                            },
                            end: {
                                hour: 11,
                                minute: 40
                            }
                        },
                        {
                            name: 'Period Four B',
                            begin: {
                                hour: 11,
                                minute: 45
                            },
                            end: {
                                hour: 12,
                                minute: 40
                            }
                        },
                        {
                            name: 'Second Lunch',
                            begin: {
                                hour: 12,
                                minute: 40
                            },
                            end: {
                                hour: 13,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period Five',
                            begin: {
                                hour: 13,
                                minute: 15
                            },
                            end: {
                                hour: 14,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period Six',
                            begin: {
                                hour: 14,
                                minute: 15
                            },
                            end: {
                                hour: 15,
                                minute: 10
                            }
                        },
                        {
                            name: 'After-School Tutoring',
                            begin: {
                                hour: 15,
                                minute: 20
                            },
                            end: {
                                hour: 16,
                                minute: 20
                            }
                        }
                    ]
                },
                {
                    name: 'Two Hour Late Start',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Period One',
                            begin: {
                                hour: 10,
                                minute: 40
                            },
                            end: {
                                hour: 11,
                                minute: 15
                            }
                        },
                        {
                            name: 'Period Two',
                            begin: {
                                hour: 11,
                                minute: 20
                            },
                            end: {
                                hour: 11,
                                minute: 55
                            }
                        },
                        {
                            name: 'One Lunch',
                            begin: {
                                hour: 11,
                                minute: 55
                            },
                            end: {
                                hour: 12,
                                minute: 25
                            }
                        },
                        {
                            name: 'Period Three',
                            begin: {
                                hour: 12,
                                minute: 30
                            },
                            end: {
                                hour: 13,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period Four',
                            begin: {
                                hour: 13,
                                minute: 15
                            },
                            end: {
                                hour: 13,
                                minute: 50
                            }
                        },
                        {
                            name: 'Period Five',
                            begin: {
                                hour: 13,
                                minute: 55
                            },
                            end: {
                                hour: 14,
                                minute: 30
                            }
                        },
                        {
                            name: 'Period Six',
                            begin: {
                                hour: 14,
                                minute: 35
                            },
                            end: {
                                hour: 15,
                                minute: 10
                            }
                        },
                        {
                            name: 'After-School Tutoring',
                            begin: {
                                hour: 15,
                                minute: 20
                            },
                            end: {
                                hour: 16,
                                minute: 20
                            }
                        }
                    ]
                },
                {
                    name: 'Early Release',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 7,
                                minute: 40
                            },
                            end: {
                                hour: 8,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period One',
                            begin: {
                                hour: 8,
                                minute: 40
                            },
                            end: {
                                hour: 9,
                                minute: 15
                            }
                        },
                        {
                            name: 'Period Two',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 9,
                                minute: 55
                            }
                        },
                        {
                            name: 'Period Three',
                            begin: {
                                hour: 10,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period Four',
                            begin: {
                                hour: 10,
                                minute: 40
                            },
                            end: {
                                hour: 11,
                                minute: 15
                            }
                        },
                        {
                            name: 'Period Five',
                            begin: {
                                hour: 11,
                                minute: 20
                            },
                            end: {
                                hour: 11,
                                minute: 55
                            }
                        },
                        {
                            name: 'Period Six',
                            begin: {
                                hour: 12,
                                minute: 0
                            },
                            end: {
                                hour: 12,
                                minute: 35
                            }
                        },
                        {
                            name: 'One Lunch',
                            begin: {
                                hour: 12,
                                minute: 35
                            },
                            end: {
                                hour: 12,
                                minute: 50
                            }
                        },
                        {
                            name: 'After-School Tutoring',
                            begin: {
                                hour: 13,
                                minute: 0
                            },
                            end: {
                                hour: 14,
                                minute: 0
                            }
                        }
                    ]
                },
                {
                    name: 'Pep Assembly - Lunch 1',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 7,
                                minute: 40
                            },
                            end: {
                                hour: 8,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period One',
                            begin: {
                                hour: 8,
                                minute: 40
                            },
                            end: {
                                hour: 9,
                                minute: 32
                            }
                        },
                        {
                            name: 'Period Two',
                            begin: {
                                hour: 9,
                                minute: 37
                            },
                            end: {
                                hour: 10,
                                minute: 30
                            }
                        },
                        {
                            name: 'Period Three',
                            begin: {
                                hour: 10,
                                minute: 35
                            },
                            end: {
                                hour: 11,
                                minute: 25
                            }
                        },
                        {
                            name: 'First Lunch',
                            begin: {
                                hour: 11,
                                minute: 25
                            },
                            end: {
                                hour: 11,
                                minute: 55
                            }
                        },
                        {
                            name: 'Period Four A',
                            begin: {
                                hour: 12,
                                minute: 0
                            },
                            end: {
                                hour: 12,
                                minute: 50
                            }
                        },
                        {
                            name: 'Period Five',
                            begin: {
                                hour: 12,
                                minute: 55
                            },
                            end: {
                                hour: 13,
                                minute: 45
                            }
                        },
                        {
                            name: 'Period Six',
                            begin: {
                                hour: 13,
                                minute: 50
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Pep Assembly',
                            begin: {
                                hour: 14,
                                minute: 40
                            },
                            end: {
                                hour: 15,
                                minute: 10
                            }
                        },
                        {
                            name: 'After-School Tutoring',
                            begin: {
                                hour: 15,
                                minute: 20
                            },
                            end: {
                                hour: 16,
                                minute: 20
                            }
                        }
                    ]
                },
                {
                    name: 'Pep Assembly - Lunch 2',
                    passingTime: 5,
                    schedule: [
                        {
                            name: 'Zero Period',
                            begin: {
                                hour: 7,
                                minute: 40
                            },
                            end: {
                                hour: 8,
                                minute: 35
                            }
                        },
                        {
                            name: 'Period One',
                            begin: {
                                hour: 8,
                                minute: 40
                            },
                            end: {
                                hour: 9,
                                minute: 32
                            }
                        },
                        {
                            name: 'Period Two',
                            begin: {
                                hour: 9,
                                minute: 37
                            },
                            end: {
                                hour: 10,
                                minute: 30
                            }
                        },
                        {
                            name: 'Period Three',
                            begin: {
                                hour: 10,
                                minute: 35
                            },
                            end: {
                                hour: 11,
                                minute: 25
                            }
                        },
                        {
                            name: 'Period Four B',
                            begin: {
                                hour: 11,
                                minute: 30
                            },
                            end: {
                                hour: 12,
                                minute: 20
                            }
                        },
                        {
                            name: 'Second Lunch',
                            begin: {
                                hour: 12,
                                minute: 20
                            },
                            end: {
                                hour: 12,
                                minute: 50
                            }
                        },
                        {
                            name: 'Period Five',
                            begin: {
                                hour: 12,
                                minute: 55
                            },
                            end: {
                                hour: 13,
                                minute: 45
                            }
                        },
                        {
                            name: 'Period Six',
                            begin: {
                                hour: 13,
                                minute: 50
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Pep Assembly',
                            begin: {
                                hour: 14,
                                minute: 40
                            },
                            end: {
                                hour: 15,
                                minute: 10
                            }
                        },
                        {
                            name: 'After-School Tutoring',
                            begin: {
                                hour: 15,
                                minute: 20
                            },
                            end: {
                                hour: 16,
                                minute: 20
                            }
                        }
                    ]
                }
           ]
        },
        {
            name: 'Thomas Jefferson Middle School',
            schoolCode: 'TJMS',
            types: [
                {
                    name: 'Monday - Lunch 1',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'Block 1/2',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 5
                            }
                        },
                        {
                            name: 'Win-Win',
                            begin: {
                                hour: 10,
                                minute: 10
                            },
                            end: {
                                hour: 10,
                                minute: 40
                            }
                        },
                        {
                            name: 'First Lunch',
                            begin: {
                                hour: 10,
                                minute: 45
                            },
                            end: {
                                hour: 11,
                                minute: 15
                            }
                        },
                        {
                            name: 'Block 3/4',
                            begin: {
                                hour: 11,
                                minute: 20
                            },
                            end: {
                                hour: 12,
                                minute: 30
                            }
                        },
                        {
                            name: 'Block 5/6',
                            begin: {
                                hour: 12,
                                minute: 35
                            },
                            end: {
                                hour: 13,
                                minute: 40
                            }
                        },
                        {
                            name: 'Block 7/8',
                            begin: {
                                hour: 13,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 50
                            }
                        },
                        {
                            name: 'Bus Departure',
                            begin: {
                                hour: 14,
                                minute: 58
                            },
                            end: {
                                hour: 14,
                                minute: 58
                            }
                        }
                    ]
                },
                {
                    name: 'Monday - Lunch 2',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'Block 1/2',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 5
                            }
                        },
                        {
                            name: 'Win-Win',
                            begin: {
                                hour: 10,
                                minute: 10
                            },
                            end: {
                                hour: 10,
                                minute: 40
                            }
                        },
                        {
                            name: 'Block 3/4 First Half',
                            begin: {
                                hour: 10,
                                minute: 45
                            },
                            end: {
                                hour: 11,
                                minute: 15
                            }
                        },
                        {
                            name: 'Second Lunch',
                            begin: {
                                hour: 11,
                                minute: 20
                            },
                            end: {
                                hour: 11,
                                minute: 50
                            }
                        },
                        {
                            name: 'Block 3/4 Second Half',
                            begin: {
                                hour: 11,
                                minute: 55
                            },
                            end: {
                                hour: 12,
                                minute: 30
                            }
                        },
                        {
                            name: 'Block 5/6',
                            begin: {
                                hour: 12,
                                minute: 35
                            },
                            end: {
                                hour: 13,
                                minute: 40
                            }
                        },
                        {
                            name: 'Block 7/8',
                            begin: {
                                hour: 13,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 50
                            }
                        },
                        {
                            name: 'Bus Departure',
                            begin: {
                                hour: 14,
                                minute: 58
                            },
                            end: {
                                hour: 14,
                                minute: 58
                            }
                        }
                    ]
                },
                {
                    name: 'Monday - Lunch 3',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'Block 1/2',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 5
                            }
                        },
                        {
                            name: 'Win-Win',
                            begin: {
                                hour: 10,
                                minute: 10
                            },
                            end: {
                                hour: 10,
                                minute: 40
                            }
                        },
                        {
                            name: 'Block 3/4',
                            begin: {
                                hour: 10,
                                minute: 45
                            },
                            end: {
                                hour: 11,
                                minute: 55
                            }
                        },
                        {
                            name: 'Third Lunch',
                            begin: {
                                hour: 12,
                                minute: 0
                            },
                            end: {
                                hour: 12,
                                minute: 30
                            }
                        },
                        {
                            name: 'Block 5/6',
                            begin: {
                                hour: 12,
                                minute: 35
                            },
                            end: {
                                hour: 13,
                                minute: 40
                            }
                        },
                        {
                            name: 'Block 7/8',
                            begin: {
                                hour: 13,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 50
                            }
                        },
                        {
                            name: 'Bus Departure',
                            begin: {
                                hour: 14,
                                minute: 58
                            },
                            end: {
                                hour: 14,
                                minute: 58
                            }
                        }
                    ]
                },
                {
                    name: 'Tuesday-Friday - Lunch 1',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'Block 1/2',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 15
                            }
                        },
                        {
                            name: 'Win-Win',
                            begin: {
                                hour: 10,
                                minute: 20
                            },
                            end: {
                                hour: 10,
                                minute: 50
                            }
                        },
                        {
                            name: 'First Lunch',
                            begin: {
                                hour: 10,
                                minute: 55
                            },
                            end: {
                                hour: 11,
                                minute: 25
                            }
                        },
                        {
                            name: 'Block 3/4',
                            begin: {
                                hour: 11,
                                minute: 30
                            },
                            end: {
                                hour: 12,
                                minute: 50
                            }
                        },
                        {
                            name: 'Block 5/6',
                            begin: {
                                hour: 12,
                                minute: 55
                            },
                            end: {
                                hour: 14,
                                minute: 10
                            }
                        },
                        {
                            name: 'Block 7/8',
                            begin: {
                                hour: 14,
                                minute: 15
                            },
                            end: {
                                hour: 15,
                                minute: 30
                            }
                        },
                        {
                            name: 'Bus Departure',
                            begin: {
                                hour: 15,
                                minute: 38
                            },
                            end: {
                                hour: 15,
                                minute: 38
                            }
                        }
                    ]
                },
                {
                    name: 'Tuesday-Friday - Lunch 2',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'Block 1/2',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 15
                            }
                        },
                        {
                            name: 'Win-Win',
                            begin: {
                                hour: 10,
                                minute: 20
                            },
                            end: {
                                hour: 10,
                                minute: 50
                            }
                        },
                        {
                            name: 'Block 3/4 First Half',
                            begin: {
                                hour: 10,
                                minute: 55
                            },
                            end: {
                                hour: 11,
                                minute: 35
                            }
                        },
                        {
                            name: 'Second Lunch',
                            begin: {
                                hour: 11,
                                minute: 40
                            },
                            end: {
                                hour: 12,
                                minute: 10
                            }
                        },
                        {
                            name: 'Block 3/4 Second Half',
                            begin: {
                                hour: 12,
                                minute: 15
                            },
                            end: {
                                hour: 12,
                                minute: 50
                            }
                        },
                        {
                            name: 'Block 5/6',
                            begin: {
                                hour: 12,
                                minute: 55
                            },
                            end: {
                                hour: 14,
                                minute: 10
                            }
                        },
                        {
                            name: 'Block 7/8',
                            begin: {
                                hour: 14,
                                minute: 15
                            },
                            end: {
                                hour: 15,
                                minute: 30
                            }
                        },
                        {
                            name: 'Bus Departure',
                            begin: {
                                hour: 15,
                                minute: 38
                            },
                            end: {
                                hour: 15,
                                minute: 38
                            }
                        }
                    ]
                },
                {
                    name: 'Tuesday-Friday - Lunch 3',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'Block 1/2',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 15
                            }
                        },
                        {
                            name: 'Win-Win',
                            begin: {
                                hour: 10,
                                minute: 20
                            },
                            end: {
                                hour: 10,
                                minute: 50
                            }
                        },
                        {
                            name: 'Block 3/4',
                            begin: {
                                hour: 10,
                                minute: 55
                            },
                            end: {
                                hour: 12,
                                minute: 15
                            }
                        },
                        {
                            name: 'Third Lunch',
                            begin: {
                                hour: 12,
                                minute: 20
                            },
                            end: {
                                hour: 12,
                                minute: 50
                            }
                        },
                        {
                            name: 'Block 5/6',
                            begin: {
                                hour: 12,
                                minute: 55
                            },
                            end: {
                                hour: 14,
                                minute: 10
                            }
                        },
                        {
                            name: 'Block 7/8',
                            begin: {
                                hour: 14,
                                minute: 15
                            },
                            end: {
                                hour: 15,
                                minute: 30
                            }
                        },
                        {
                            name: 'Bus Departure',
                            begin: {
                                hour: 15,
                                minute: 38
                            },
                            end: {
                                hour: 15,
                                minute: 38
                            }
                        }
                    ]
                }
           ]
        },
        {
            name: 'Laurin Middle School',
            schoolCode: 'LMS',
            types: [
                {
                    name: 'MTThF Normal Schedule - 4a',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 8,
                                minute: 0
                            },
                            end: {
                                hour: 8,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 8,
                                minute: 10
                            },
                            end: {
                                hour: 9,
                                minute: 6
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 9,
                                minute: 9
                            },
                            end: {
                                hour: 10,
                                minute: 5
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 10,
                                minute: 8
                            },
                            end: {
                                hour: 11,
                                minute: 4
                            }
                        },
                        {
                            name: 'Period 4a',
                            begin: {
                                hour: 11,
                                minute: 7
                            },
                            end: {
                                hour: 12,
                                minute: 3
                            }
                        },
                        {
                            name: 'Lunch 5 / Recess 7',
                            begin: {
                                hour: 12,
                                minute: 3
                            },
                            end: {
                                hour: 12,
                                minute: 23
                            }
                        },
                        {
                            name: 'Lunch 7 / Recess 5',
                            begin: {
                                hour: 12,
                                minute: 3
                            },
                            end: {
                                hour: 12,
                                minute: 43
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 12,
                                minute: 45
                            },
                            end: {
                                hour: 13,
                                minute: 41
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 13,
                                minute: 44
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: 'MTThF Normal Schedule - 4b',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 8,
                                minute: 0
                            },
                            end: {
                                hour: 8,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 8,
                                minute: 10
                            },
                            end: {
                                hour: 9,
                                minute: 6
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 9,
                                minute: 9
                            },
                            end: {
                                hour: 10,
                                minute: 5
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 10,
                                minute: 8
                            },
                            end: {
                                hour: 11,
                                minute: 4
                            }
                        },
                        {
                            name: 'Lunch 6 / Recess 8',
                            begin: {
                                hour: 11,
                                minute: 4
                            },
                            end: {
                                hour: 11,
                                minute: 24
                            }
                        },
                        {
                            name: 'Lunch 8 / Recess 6',
                            begin: {
                                hour: 11,
                                minute: 24
                            },
                            end: {
                                hour: 11,
                                minute: 44
                            }
                        },
                        {
                            name: 'Period 4b',
                            begin: {
                                hour: 11,
                                minute: 46
                            },
                            end: {
                                hour: 12,
                                minute: 42
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 12,
                                minute: 45
                            },
                            end: {
                                hour: 13,
                                minute: 41
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 13,
                                minute: 44
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: 'Wed. Late Start/Advisory - 4a',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 9,
                                minute: 10
                            }
                        },
                        {
                            name: 'Advisory',
                            begin: {
                                hour: 9,
                                minute: 10
                            },
                            end: {
                                hour: 10,
                                minute: 6
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 10,
                                minute: 8
                            },
                            end: {
                                hour: 10,
                                minute: 45
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 10,
                                minute: 47
                            },
                            end: {
                                hour: 11,
                                minute: 24
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 11,
                                minute: 26
                            },
                            end: {
                                hour: 12,
                                minute: 3
                            }
                        },
                        {
                            name: 'Period 4a',
                            begin: {
                                hour: 12,
                                minute: 5
                            },
                            end: {
                                hour: 12,
                                minute: 42
                            }
                        },
                        {
                            name: 'Lunch 5 / Recess 7',
                            begin: {
                                hour: 12,
                                minute: 42
                            },
                            end: {
                                hour: 13,
                                minute: 2
                            }
                        },
                        {
                            name: 'Lunch 7 / Recess 5',
                            begin: {
                                hour: 13,
                                minute: 2
                            },
                            end: {
                                hour: 13,
                                minute: 22
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 13,
                                minute: 24
                            },
                            end: {
                                hour: 14,
                                minute: 1
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 14,
                                minute: 3
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: 'Wed. Late Start/Advisory - 4b',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 9,
                                minute: 0
                            },
                            end: {
                                hour: 9,
                                minute: 10
                            }
                        },
                        {
                            name: 'Advisory',
                            begin: {
                                hour: 9,
                                minute: 10
                            },
                            end: {
                                hour: 10,
                                minute: 6
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 10,
                                minute: 8
                            },
                            end: {
                                hour: 10,
                                minute: 45
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 10,
                                minute: 47
                            },
                            end: {
                                hour: 11,
                                minute: 24
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 11,
                                minute: 26
                            },
                            end: {
                                hour: 12,
                                minute: 3
                            }
                        },
                        {
                            name: 'Lunch 6 / Recess 8',
                            begin: {
                                hour: 12,
                                minute: 3
                            },
                            end: {
                                hour: 12,
                                minute: 23
                            }
                        },
                        {
                            name: 'Lunch 8 / Recess 6',
                            begin: {
                                hour: 12,
                                minute: 23
                            },
                            end: {
                                hour: 12,
                                minute: 43
                            }
                        },
                        {
                            name: 'Period 4b',
                            begin: {
                                hour: 12,
                                minute: 45
                            },
                            end: {
                                hour: 13,
                                minute: 22
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 13,
                                minute: 24
                            },
                            end: {
                                hour: 14,
                                minute: 1
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 14,
                                minute: 3
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: '2 Hour Late Start - 4a',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 10,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 10,
                                minute: 10
                            },
                            end: {
                                hour: 10,
                                minute: 46
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 10,
                                minute: 48
                            },
                            end: {
                                hour: 11,
                                minute: 24
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 11,
                                minute: 27
                            },
                            end: {
                                hour: 12,
                                minute: 3
                            }
                        },
                        {
                            name: 'Period 4a',
                            begin: {
                                hour: 12,
                                minute: 6
                            },
                            end: {
                                hour: 12,
                                minute: 42
                            }
                        },
                        {
                            name: 'Lunch 5 / Recess 7',
                            begin: {
                                hour: 12,
                                minute: 42
                            },
                            end: {
                                hour: 13,
                                minute: 2
                            }
                        },
                        {
                            name: 'Lunch 7 / Recess 5',
                            begin: {
                                hour: 13,
                                minute: 2
                            },
                            end: {
                                hour: 13,
                                minute: 22
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 13,
                                minute: 25
                            },
                            end: {
                                hour: 14,
                                minute: 1
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 14,
                                minute: 4
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: '2 Hour Late Start - 4b',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 10,
                                minute: 0
                            },
                            end: {
                                hour: 10,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 10,
                                minute: 10
                            },
                            end: {
                                hour: 10,
                                minute: 46
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 10,
                                minute: 48
                            },
                            end: {
                                hour: 11,
                                minute: 24
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 11,
                                minute: 27
                            },
                            end: {
                                hour: 12,
                                minute: 3
                            }
                        },
                        {
                            name: 'Lunch 6 / Recess 8',
                            begin: {
                                hour: 12,
                                minute: 3
                            },
                            end: {
                                hour: 12,
                                minute: 23
                            }
                        },
                        {
                            name: 'Lunch 8 / Recess 6',
                            begin: {
                                hour: 12,
                                minute: 23
                            },
                            end: {
                                hour: 12,
                                minute: 43
                            }
                        },
                        {
                            name: 'Period 4b',
                            begin: {
                                hour: 12,
                                minute: 45
                            },
                            end: {
                                hour: 13,
                                minute: 21
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 13,
                                minute: 25
                            },
                            end: {
                                hour: 14,
                                minute: 1
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 14,
                                minute: 4
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: 'Assembly Schedule - 4a',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 8,
                                minute: 0
                            },
                            end: {
                                hour: 8,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 8,
                                minute: 10
                            },
                            end: {
                                hour: 8,
                                minute: 56
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 8,
                                minute: 58
                            },
                            end: {
                                hour: 9,
                                minute: 44
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 9,
                                minute: 46
                            },
                            end: {
                                hour: 10,
                                minute: 32
                            }
                        },
                        {
                            name: 'Period 4a',
                            begin: {
                                hour: 12,
                                minute: 6
                            },
                            end: {
                                hour: 12,
                                minute: 42
                            }
                        },
                        {
                            name: 'Lunch 5 / Recess 7',
                            begin: {
                                hour: 12,
                                minute: 42
                            },
                            end: {
                                hour: 13,
                                minute: 2
                            }
                        },
                        {
                            name: 'Lunch 7 / Recess 5',
                            begin: {
                                hour: 13,
                                minute: 2
                            },
                            end: {
                                hour: 13,
                                minute: 22
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 12,
                                minute: 2
                            },
                            end: {
                                hour: 12,
                                minute: 48
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 12,
                                minute: 50
                            },
                            end: {
                                hour: 13,
                                minute: 36
                            }
                        },
                        {
                            name: 'Assembly',
                            begin: {
                                hour: 13,
                                minute: 40
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: 'Assembly Schedule - 4b',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 8,
                                minute: 0
                            },
                            end: {
                                hour: 8,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 8,
                                minute: 10
                            },
                            end: {
                                hour: 8,
                                minute: 56
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 8,
                                minute: 58
                            },
                            end: {
                                hour: 9,
                                minute: 44
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 9,
                                minute: 46
                            },
                            end: {
                                hour: 10,
                                minute: 32
                            }
                        },
                        {
                            name: 'Lunch 6 / Recess 8',
                            begin: {
                                hour: 10,
                                minute: 32
                            },
                            end: {
                                hour: 10,
                                minute: 52
                            }
                        },
                        {
                            name: 'Lunch 8 / Recess 6',
                            begin: {
                                hour: 10,
                                minute: 52
                            },
                            end: {
                                hour: 11,
                                minute: 12
                            }
                        },
                        {
                            name: 'Period 4b',
                            begin: {
                                hour: 11,
                                minute: 14
                            },
                            end: {
                                hour: 12,
                                minute: 0
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 12,
                                minute: 2
                            },
                            end: {
                                hour: 12,
                                minute: 48
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 12,
                                minute: 50
                            },
                            end: {
                                hour: 13,
                                minute: 36
                            }
                        },
                        {
                            name: 'Assembly',
                            begin: {
                                hour: 13,
                                minute: 40
                            },
                            end: {
                                hour: 14,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 14,
                                minute: 45
                            },
                            end: {
                                hour: 14,
                                minute: 45
                            }
                        }
                    ]
                },
                {
                    name: '3 Hour Early Release (Conferences)',
                    passingTime: 3,
                    schedule: [
                        {
                            name: 'First Bell',
                            begin: {
                                hour: 8,
                                minute: 0
                            },
                            end: {
                                hour: 8,
                                minute: 10
                            }
                        },
                        {
                            name: 'Period 1',
                            begin: {
                                hour: 8,
                                minute: 10
                            },
                            end: {
                                hour: 8,
                                minute: 43
                            }
                        },
                        {
                            name: 'Period 2',
                            begin: {
                                hour: 8,
                                minute: 45
                            },
                            end: {
                                hour: 9,
                                minute: 18
                            }
                        },
                        {
                            name: 'Period 3',
                            begin: {
                                hour: 9,
                                minute: 20
                            },
                            end: {
                                hour: 9,
                                minute: 53
                            }
                        },
                        {
                            name: 'Period 4',
                            begin: {
                                hour: 9,
                                minute: 55
                            },
                            end: {
                                hour: 10,
                                minute: 28
                            }
                        },
                        {
                            name: 'Period 5',
                            begin: {
                                hour: 10,
                                minute: 30
                            },
                            end: {
                                hour: 11,
                                minute: 3
                            }
                        },
                        {
                            name: 'Period 6',
                            begin: {
                                hour: 11,
                                minute: 5
                            },
                            end: {
                                hour: 11,
                                minute: 40
                            }
                        },
                        {
                            name: 'Bus Bell',
                            begin: {
                                hour: 11,
                                minute: 45
                            },
                            end: {
                                hour: 11,
                                minute: 45
                            }
                        }
                    ]
                }
           ]
        }
    ]
}