import React from "react";
import { Redirect } from 'react-router-dom'
import ScheduleTable from "../components/ScheduleTable"
import Header from "../components/Header";
import Footer from "../components/Footer";
import { default as data } from "../models/schedules";
import { schoolCodeToObject } from "../functions/Globals.js";
import "../styles/ScheduleView.css"

function ScheduleView(props) {
    let schoolSelected = schoolCodeToObject(data, props.match.params.school);
    if(schoolSelected) {
        var schoolTables = schoolSelected.types.map((type, i) => <ScheduleTable type={type} key={i} />);
    }

    return (
        schoolSelected?
            <div className='paddingBottom'>
                <Header />

                <h3>{schoolSelected.name} Schedules:</h3>

                <div className="scheduleView">
                    {schoolTables}
                </div>
                
                <Footer />
            </div>
        :
            <Redirect to="/" />
    );
}

export default ScheduleView;