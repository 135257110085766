import React from 'react';
import '../styles/Main.css';
import { default as data } from '../models/schedules';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Remaining from "../components/Remaining";
import SelectSchool from "../components/SelectSchool";
import Alarm from '../components/Alarm';

import AudioContext from '../contexts/AudioContext';

import { setCookie, getCookies, schoolCodeToObject } from "../functions/Globals.js";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolSelected: undefined,
            schoolSelectedCode: '',
            scheduleOptions: undefined,
            scheduleSelectedIndex: 0,
            timeOffset: 0,
            timeState: {},
            online: false,
            cleanView: false,
            error: null
        };
    }

    componentDidMount() {
        let cookies = getCookies();

        let schoolSelectedCode = cookies['schoolSelectedCode'];
        let scheduleSelectedIndex = parseInt(cookies['scheduleSelectedIndex']) || this.state.scheduleSelectedIndex;
        if (schoolSelectedCode) {
            let schoolSelected = schoolCodeToObject(data, schoolSelectedCode)

            let scheduleOptions = this.updateScheduleOptions(schoolSelected);

            this.setState({
                schoolSelected: schoolSelected,
                schoolSelectedCode: schoolSelected.schoolCode,
                scheduleOptions: scheduleOptions,
                scheduleSelectedIndex: scheduleSelectedIndex,
            });
        }
        this.getServerTime();
    }

    error(message) {
        this.setState({
            error: message
        })
    }

    getServerTime() {
        let protocol = 'https';
        if (window.location.protocol !== 'https:') {
            protocol = 'http';
        }

        let timeAPI = protocol + '://worldtimeapi.org/api/ip';

        // Open connection before getting data to get the quickest response
        fetch(timeAPI)
            .then(() => {
                let clientTime = new Date();
                fetch(timeAPI)
                    .then((data) => {
                        data.json().then((data => {
                            let serverTime = new Date(data.datetime)
                            let timeOffset = serverTime - clientTime
                            console.log(`Time offset from server: ${timeOffset}ms`);

                            this.setState({
                                timeOffset: timeOffset,
                                online: true
                            })
                        }));
                    })
                    .catch((err) => {
                        this.error("Could not synchronize to server time. We'll use your phone time instead.");
                    });
            })
            .catch((err) => {
                this.error("Could not synchronize to server time. We'll use your phone time instead.");
            });
    }

    updateScheduleOptions(school) {
        return school.types.map((schedule, i) => {
            return (<option key={i} value={i} className='width100'>{schedule.name}</option>);
        });
    }

    unsetSchool() {
        this.setState({
            schoolSelected: undefined
        });
    }

    updateSchool(schoolCode) {
        let schoolObject = schoolCodeToObject(data, schoolCode);
        this.setState({
            schoolSelected: schoolObject,
            schoolSelectedCode: schoolObject.schoolCode,
            scheduleOptions: this.updateScheduleOptions(schoolObject),
            scheduleSelectedIndex: 0,
        });

        setCookie('schoolSelectedCode', schoolCode, 365);
        setCookie('scheduleSelectedIndex', 0, 1);
    }

    updateSchedule(scheduleIndex) {
        this.setState({
            scheduleSelectedIndex: scheduleIndex,
        });

        setCookie('scheduleSelectedIndex', scheduleIndex, 1);
    }

    toggleCleanView() {
        this.setState({
            cleanView: !this.state.cleanView
        });
    }

    updateTimeState(timeStateObj) {
        this.setState({
            timeState: timeStateObj
        });
    }

    render() {
        return (
            <div className='height100 flexContainer flexColumn'>
                <div className={"spacer " + (!this.state.cleanView ? "hidden" : "")}></div>
                <div className={'header ' + (this.state.cleanView ? "hidden" : "")}>
                    <Header
                        school={this.state.schoolSelected}
                        unset={() => this.unsetSchool()}
                    />
                    {this.state.schoolSelected ?
                        <div className='flexContainer'>
                            <select
                                className='scheduleSelect'
                                value={this.state.scheduleSelectedIndex}
                                onChange={evt => this.updateSchedule(evt.target.value)}
                                aria-label="Select schedule"
                                tabIndex="1"
                            >
                                {this.state.scheduleOptions}
                            </select>
                            <button
                                className="mainButton scheduleViewButton"
                                onClick={() => this.props.history.push('/school/' + this.state.schoolSelectedCode + '/schedules')}
                                aria-label="View all schedules"
                            >
                                <img src="/list.png" className="listIcon" alt="List icon" />
                            </button>
                            <div className='spacer'></div>

                            <AudioContext.Consumer>
                                {({ audioContext, setAudioContext }) => (
                                    <Alarm
                                        remaining={this.state.timeState}
                                        audioContext={audioContext}
                                        setAudioContext={(audioContext) => setAudioContext(audioContext)}
                                    />
                                )}
                            </AudioContext.Consumer>
                            
                            <button
                                className="mainButton cleanViewButton"
                                onClick={() => this.toggleCleanView()}
                                tooltip="Clean View"
                                aria-label="Clean view"
                            >
                                <img src="/fullscreen.png" className="cleanViewIcon" alt="Expand icon"/>
                            </button>
                        </div>
                        : ""}
                </div>
                <div className='content'>
                    <div className={"exitCleanViewButton " + (!this.state.cleanView ? "hidden" : "")}>
                        <button
                            className="mainButton"
                            onClick={() => this.toggleCleanView()}
                        >Full View</button>
                    </div>
                    {this.state.schoolSelected ?
                        <Remaining
                            currentSchedule={this.state.schoolSelected.types[this.state.scheduleSelectedIndex].schedule}
                            timeOffset={this.state.timeOffset}
                            updateTimeState={(timeStateObj) => this.updateTimeState(timeStateObj)}
                        />
                        :
                        <SelectSchool
                            saveSchool={(schoolCode) => this.updateSchool(schoolCode)}
                        />
                    }
                    <div className={!this.state.cleanView ? "hidden" : ""}>
                        <small>Provided by LiveBell.org</small>
                    </div>
                </div>
                <div className={"footer " + (this.state.cleanView ? "hidden" : "")}>
                    <Footer online={this.state.online} />
                </div>
                <div className={"spacer " + (!this.state.cleanView ? "hidden" : "")}></div>
            </div>
        );
    }
}

export default Main;