import React from 'react';
import { Link } from "react-router-dom";
import '../styles/SelectSchool.css';

import { default as data } from '../models/schedules';

class SelectSchool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolList: [],
            schoolSelected: null
        };
    }

    componentDidMount() {
        let schoolList = data.schedules.map((school) => {
            return {
                name: school.name,
                code: school.schoolCode
            }
        })

        this.setState({
            schoolList: schoolList
        })
    }

    selectSchool(e) {
        this.setState({
            schoolSelected: e.currentTarget.value
        });
    }

    render() {
        this.state.schoolList.sort((a, b) => (a.name > b.name) ? 1 : -1)
        let schoolOptionList = this.state.schoolList.map((school) => {
            return (
                <div key={school.code}>
                    <input
                        type="radio"
                        id={school.code}
                        name="schools"
                        value={school.code}
                        checked={this.state.schoolSelected === school.code}
                        onChange={(e) => this.selectSchool(e)}
                    />
                    <label htmlFor={school.code}>
                        <div>
                            {school.name}
                        </div>
                    </label>
                </div>
            );
        })

        return (
            <div className="selectSchool height100 flexContainer flexColumn">
                <h2>Select your school:</h2>
                <div className="selectSchoolContainer">
                    {schoolOptionList}
                </div>
                <div className="flexContainer">
                    <Link to="/contact" className="schoolNotListed">
                        Don't see your school?
                    </Link>
                    <div className='spacer'></div>
                    <button
                        className="selectSchoolButton"
                        onClick={() => this.props.saveSchool(this.state.schoolSelected)}
                        disabled={!this.state.schoolSelected}
                    >
                        Save &rarr;
                    </button>
                </div>
                <small className="privacyDisclosure">
                    By continuing to use LiveBell.org, you consent to the use of cookies on the site. See the <Link to="/privacy">LiveBell Privacy Policy</Link> for more details on how cookies are used on this site.
                </small>
            </div>
        );
    }
}

export default SelectSchool;