import React from 'react';
import '../styles/Alarm.css'

class Alarm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alarm: false,
            minutesBefore: 5,
            addingInterval: null
        };
        this.alarmRang = false;
    }

    componentDidUpdate() {
        if (this.props.audioContext && 
            this.state.alarm && 
            this.props.remaining.hoursRemaining === 0 && 
            this.props.remaining.secondsRemaining === 0 && 
            this.props.remaining.minutesRemaining === this.state.minutesBefore) {
            this.alarm();
        }
    }

    setupAlarm() {
        let AudioContext = window.AudioContext // Default
            || window.webkitAudioContext // Safari and old versions of Chrome
            || false;

        let context = new AudioContext();

        this.setState({
            alarm: !this.state.alarm
        }, () => {
            try {
                this.tone(context, 0, 0.00001);
                context.suspend();
            } catch (error) {
                console.error("Problem initializing alarm audio. Likely an issue with your browser's AudioContext");
                console.error(error);
                window.location.reload(false);
            }
        });

        this.props.setAudioContext(context);
    }

    tone(context, frequency, volume = 1) {
            let o = context.createOscillator();
            let g = context.createGain();
            o.connect(g);
            g.connect(context.destination);
            o.type = 'triangle';
            o.frequency.value = frequency;
            o.start(0);

            g.gain.exponentialRampToValueAtTime(0.00001, context.currentTime);
            g.gain.exponentialRampToValueAtTime(volume, context.currentTime + 0.1);
            g.gain.exponentialRampToValueAtTime(0.00001, context.currentTime + 2.5);
    }

    alarm() {
        if (!this.alarmRang) {
            let context = this.props.audioContext;
            context.resume();

            this.alarmRang = true;
            this.tone(context, 293.66);
            this.tone(context, 246.94);

            setTimeout(() => {
                this.tone(context, 246.94);
                this.tone(context, 196.00);
                this.alarmRang = false;
            }, 1000);

            setTimeout(() => {
                context.suspend();
            }, 3000);
        }
    }

    addToMinutesBefore(n) {
        if (this.state.minutesBefore + n < 60 && this.state.minutesBefore + n > 0) {
            this.setState({
                minutesBefore: this.state.minutesBefore + n
            });
        }
    }

    startAddToMinutesBefore(n) {
        this.setState({
            addingInterval: setInterval(() => this.addToMinutesBefore(n), 250)
        });
    }

    endAddToMinutesBefore() {
        clearInterval(this.state.addingInterval);
    }

    render() {
        return (
            <div className="alarmContainer">
                
                <div className={`alarmControls ${!this.state.alarm? "alarmControlsInvisible" : ""}`}>
                    
                    <button
                        className="mainButton alarmAdjustButton"
                        aria-label="Decrease alarm time by 1 minute"
                        tabIndex="3"
                        onClick={() => this.addToMinutesBefore(-1)}
                        onMouseDown={() => this.startAddToMinutesBefore(-1)}
                        onMouseUp={() => this.endAddToMinutesBefore()}
                        onMouseLeave={() => this.endAddToMinutesBefore()}
                        onTouchStart={() => this.startAddToMinutesBefore(-1)}
                        onTouchEnd={() => this.endAddToMinutesBefore()}
                    >-</button>
                    <div className="minutesBeforeInput">
                        <div role="textbox" aria-readonly="true" aria-label="Minutes before period end">
                            {this.state.minutesBefore}
                        </div>
                        <div className="alarmMinutesLabel" id="alarmMinutesLabel">
                            Minutes
                        </div>
                    </div>
                    <button
                        className="mainButton alarmAdjustButton"
                        aria-label="Increase alarm time by 1 minute"
                        tabIndex="4"
                        onClick={() => this.addToMinutesBefore(1)}
                        onMouseDown={() => this.startAddToMinutesBefore(1)}
                        onMouseUp={() => this.endAddToMinutesBefore()}
                        onMouseLeave={() => this.endAddToMinutesBefore()}
                        onTouchStart={() => this.startAddToMinutesBefore(1)}
                        onTouchEnd={() => this.endAddToMinutesBefore()}
                    >+</button>
                    
                </div>
                <button
                    onClick={() => this.setupAlarm()}
                    className="mainButton alarmToggle"
                    role="switch"
                    aria-checked={this.state.alarm}
                    aria-label="Alarm toggle"
                    tabIndex="2"
                >
                    <img src="/alarm.png" className="alarmIcon" alt="Alarm" />
                </button>
            </div>
        );
    }
}

export default Alarm