import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AudioContext from './contexts/AudioContext';
import Main from "./views/Main";
import About from "./views/About";
import Contact from "./views/Contact";
import Privacy from "./views/Privacy";
import ScheduleView from "./views/ScheduleView";
import ScrollToTop from "./components/ScrollToTop";
import './styles/App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.setAudioContext = (audioContext) => {
      this.setState({
        audioContext: audioContext
      });
    };

    this.state = {
      audioContext: null,
      setAudioContext: this.setAudioContext,
    };
  }

  render() {
    return (
      <div className='App'>
        <AudioContext.Provider value={this.state}>
          <Router>
            <Switch>
              <Route exact path='/' component={Main} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/privacy' component={Privacy} />
              <Route path='/school/:school/schedules' component={ScheduleView} />
            </Switch>
            <ScrollToTop />
          </Router>
        </AudioContext.Provider>
      </div>
    );
  }
}

export default App;
