import React from 'react';
import '../styles/Remaining.css';

class Remaining extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            endOfDay: false,
            isEnding: false,
            currentPeriodName: '',
            hoursRemaining: 0,
            minutesRemaining: 0,
            secondsRemaining: 0
        };
    }

    componentDidMount() {
        this.tick();
        let msUntilSecond = 1000 - new Date().getMilliseconds();
        window.setTimeout(() => {
            this.tick();
            this.interval = setInterval(
                () => this.tick(),
                100
            );
        }, msUntilSecond);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getCurrentDate() {
        return new Date(Date.now() + this.props.timeOffset);
    }

    checkIfInFuture(schedulePeriodPart) {
        let currentDate = this.getCurrentDate()
        let currentHour = currentDate.getHours();
        let currentMinute = currentDate.getMinutes();

        return (
            schedulePeriodPart.hour > currentHour
            ||
            (schedulePeriodPart.hour === currentHour && schedulePeriodPart.minute > currentMinute)
        );
    }

    getTimeUntil(schedulePeriodPart) {
        let currentDate = this.getCurrentDate()
        let schedulePeriodPartDate = new Date();
        schedulePeriodPartDate.setHours(schedulePeriodPart.hour, schedulePeriodPart.minute, 0, 0);

        let timeBetween = (schedulePeriodPartDate - currentDate) / 1000;

        let hoursRemaining = Math.floor((timeBetween) / 3600);
        let minutesRemaining = Math.floor((timeBetween) / 60) % 60;
        let secondsRemaining = Math.floor((timeBetween - (minutesRemaining * 60)) % 60);

        return ([
            hoursRemaining,
            minutesRemaining,
            secondsRemaining
        ]);
    }

    tick() {
        let currentSchedule = this.props.currentSchedule;

        let currentPeriod, hoursRemaining, minutesRemaining, secondsRemaining;
        let endOfDay = false;
        let isEnding = false;
        for (var i = 0; i < currentSchedule.length; i++) {
            currentPeriod = currentSchedule[i];
            if (this.checkIfInFuture(currentPeriod.begin)) {
                [hoursRemaining, minutesRemaining, secondsRemaining] = this.getTimeUntil(currentPeriod.begin);
                break;
            } else if (this.checkIfInFuture(currentPeriod.end)) {
                [hoursRemaining, minutesRemaining, secondsRemaining] = this.getTimeUntil(currentPeriod.end);
                isEnding = true;
                break;
            } else if (i === currentSchedule.length - 1) {
                endOfDay = true;
            }
        }

        this.setState({
            endOfDay: endOfDay,
            isEnding: isEnding,
            currentPeriodName: currentPeriod.name,
            hoursRemaining: hoursRemaining,
            minutesRemaining: minutesRemaining,
            secondsRemaining: secondsRemaining,
        });

        this.props.updateTimeState({
            endOfDay: endOfDay,
            isEnding: isEnding,
            currentPeriodName: currentPeriod.name,
            hoursRemaining: hoursRemaining,
            minutesRemaining: minutesRemaining,
            secondsRemaining: secondsRemaining,
        });
    }

    render() {
        if (this.state.endOfDay) {
            return (
                <div>
                    <h2 className='endOfDay'>That's all for today!</h2>
                </div>
            );
        } else {
            return (
                <div>
                    <h2 className='timeUntilHeading'>
                        Time until the {this.state.isEnding ? 'end' : 'start'} of {this.state.currentPeriodName}:
                    </h2>
                    <h1 className='countdown'>
                        {
                            this.state.hoursRemaining > 0 ?
                                this.state.hoursRemaining + ':'
                                :
                                ''
                        }
                        {
                            this.state.minutesRemaining >= 10 ?
                                this.state.minutesRemaining
                                :
                                '0' + this.state.minutesRemaining
                        }
                        :
                        {
                            this.state.secondsRemaining >= 10 ?
                                this.state.secondsRemaining
                                :
                                '0' + this.state.secondsRemaining
                        }
                    </h1>
                </div>
            );
        }
    }
}

export default Remaining;