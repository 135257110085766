import React from "react";
import "../styles/ScheduleTable.css"

function ScheduleTable(props) {
    let type = props.type;
    let schedule = type.schedule;

    let scheduleTableElem = schedule.map(({name, begin, end}, i) => {
        let beginHour = begin.hour > 12 ? begin.hour - 12 : begin.hour;
        let endHour = end.hour > 12 ? end.hour - 12 : end.hour;

        let beginMinute = begin.minute < 10 ? '0' + begin.minute : begin.minute;
        let endMinute = end.minute < 10 ? '0' + end.minute : end.minute;

        let beginAMPM = begin.hour < 12 ? 'AM' : 'PM';
        let endAMPM = end.hour < 12 ? 'AM' : 'PM';
        
        return (
            <tr key={i}>
                <td className="scheduleTablePeriodName">
                    {name}
                </td>
                <td className="scheduleTablePeriodStart">
                    <span>{beginHour}:{beginMinute}</span>
                    <span className="scheduleTableAMPM">{beginAMPM}</span>
                </td>
                <td className="scheduleTablePeriodDash">
                    -
                </td>
                <td className="scheduleTablePeriodEnd">
                    <span>{endHour}:{endMinute}</span>
                    <span className="scheduleTableAMPM">{endAMPM}</span>
                </td>
            </tr>
        );
    });
    return (
        <div className="scheduleTableContainer">
            <h2 className="scheduleTableName">{type.name}</h2>
            <table className="scheduleTable">
                <thead>
                    <tr>
                        <th className="scheduleTablePeriodNameHeader">
                            Period Name
                        </th>
                        <th>
                            Start
                        </th>
                        <th></th>
                        <th>
                            End
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {scheduleTableElem}
                </tbody>
            </table>
        </div>
    );
}

export default ScheduleTable;